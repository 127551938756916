.border-container {
  /*border: 20px solid hsl(240, 4%, 36%);*/
  /*margin: 6rem 5rem 4rem 5rem;*/
  margin: 6rem 0rem 4rem 0rem;
}



.pd-swiper {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
}

.pd-swiper-slide {
  justify-content: center;
  background-position: center;
  background-size: cover;
  width: 720px;
  height: 480px;

}

.pd-swiper-slide img {
  width: 100%;
  height: 95%;
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.54);
  box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.54);
  border-radius: 25px;
  border: 3px solid #e4dbc7;
}

.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
}

.swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  border: 2px solid grey;
  background-color: rgba(0, 0, 0, 0);
  opacity: 1;
}

.swiper-pagination-bullet:hover {
  transform: scale(1.1);
}

.swiper-pagination-bullet-active {
  width: 14px;
  height: 14px;
  background: hsl(26.03deg 37.52% 39.39%);
  border: none;
}

.pagination_dot_active {
  border: none;
  background-color: white;
}

.swiper-button-next,
.swiper-button-prev {
  color: #e4dbc7;
  position: absolute;
  top: 50%;
  transform: translateY(30%);
}

.swiper-button-prev {
  padding-left: 1%;
}

.swiper-button-next {
  padding-right: 1%;
}


.background-hugy {
  /*background: var(--bodyColor);*/
  /*background-color: #fbfeef;*/
  /*background-image: url("../../../public/images/HugyBackground2.jpg");*/
  background-size: 30%;
  background-repeat: round;
}

.products-details-section {
  margin: 4rem 7rem 4rem 7rem;
  text-align: center;
  border: 8px solid #e4dbc7;
  background-color: #F0F0F6;
  -webkit-box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.54);
  box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.54);
  padding: 5rem;
}

.gg {
  color: hsl(26.03deg 37.52% 39.39%);
  text-transform: uppercase;
  font-size: 40px;
  margin: 7rem 0 0 0;
  text-align: center;
}

.products-details-text-title {
  color: hsl(26.03deg 37.52% 39.39%);
  font-size: 32px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.products-details-text-info {
  font-size: 18px;
  color: hsl(240, 4%, 36%);
  font-weight: 500;
}

@media (min-width: 1600px) {
  .swiper-slide {
    width: 845px;
    height: 565px;
  }
}

@media (min-width: 1800px) {
  .swiper-slide {
    width: 950px;
    height: 635px;
  }
}

@media (min-width: 1920px) {
  .swiper-slide {
    width: 1000px;
    height: 665px;
  }
}

@media (max-width: 1200px) {
  .swiper-slide {
    width: 632px;
    height: 420px;
  }
}

@media (max-width: 1100px) {
  .border-container {
    margin: 6rem 2rem 4rem 2rem;
  }
}

@media (max-width: 800px) {

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

@media (max-width: 769px) {
  .swiper-slide {
    width: 475px;
    height: 320px;
  }

  .border-container {
    margin: 6rem 1rem 4rem 1rem;
  }

  .products-details-section {
    margin: 4rem 2rem 4rem 2rem;
    padding: 2rem;
  }
}

@media (max-width: 650px) {
  .border-container {
    margin: 6rem 0.5rem 4rem 0.5rem;
  }
}

@media (max-width: 520px) {
  .swiper-slide {
    width: 420px;
    height: 280px;
  }

  .products-details-section {
    margin: 4rem 1rem 4rem 1rem;
    padding: 2rem 1rem;
  }
}

@media (max-width: 460px) {
  .swiper-slide {
    width: 390px;
    height: 260px;
  }

  .products-details-section {
    margin: 4rem 0.5rem 4rem 0.5rem;
    padding: 2rem 1rem;
  }

  .border-container {
    margin: 6rem 0.1rem 4rem 0.1rem;
  }
}

@media (max-width: 420px) {
  .swiper-slide {
    width: 350px;
    height: 235px;
  }
}

@media (max-width: 370px) {
  .swiper-slide {
    width: 320px;
    height: 215px;
  }
}

@media (max-width: 340px) {
  .swiper-slide {
    width: 300px;
    height: 200px;
  }
}

@media (max-width: 315px) {
  .swiper-slide {
    width: 280px;
    height: 185px;
  }
}

@media (max-width: 290px) {
  .swiper-slide {
    width: 260px;
    height: 185px;
  }
}

@media (max-width: 275px) {
  .swiper-slide {
    width: 250px;
    height: 185px;
  }
}

@media (max-width: 255px) {
  .swiper-slide {
    width: 240px;
    height: 185px;
  }
}

@media (max-width: 245px) {
  .swiper-slide {
    width: 230px;
    height: 185px;
  }
}

@media (max-width: 230px) {
  .swiper-slide {
    width: 210px;
    height: 185px;
  }
}