:root {
  --PrimaryColor: hsl(0, 93%, 27%);
  --SecondryColor: hsl(0, 76%, 47%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
  --gradientColor: linear-gradient(to right,
      hsl(187, 85%, 43%),
      hsl(199, 100%, 33%));

  --redGradientColor: linear-gradient(to right top,
      hsl(0, 93%, 27%),
      hsl(0, 85%, 43%),
      hsl(0, 76%, 47%),
      hsl(0, 64%, 77%));
}


.home-swiper {
  width: 100%;
  height: 1000px;
  border: none;
}

.home-swiper-slide {
  background-position: center;
  background-size: cover;
}

.home-swiper-slide img {
  display: block;
  width: 100%;
  height: 1000px;
}



.home-swiper .swiper-pagination-bullet-active {
  background-color: white;
}




@keyframes animate {
  0% {
    top: -5px;
    left: -5px;
    opacity: 0;
  }

  25% {
    top: 0px;
    left: 0px;
    opacity: 1;
  }

  50%,
  100% {
    top: 5px;
    left: 5px;
    opacity: 0;
  }
}


@media screen and (max-width: 1200px) {


  .home-swiper {
    height: 800px;
  }

  .home-swiper-slide img {
    height: 800px;
  }

}


@media screen and (max-width: 900px) {


  .home-swiper {
    height: 700px;
  }

  .home-swiper-slide img {
    height: 700px;
  }

}

@media screen and (max-width: 800px) {


  .home-swiper {
    height: 600px;
  }

  .home-swiper-slide img {
    height: 600px;
  }

}



@media screen and (max-width: 700px) {


  .home-swiper {
    height: 900px;
  }

  .home-swiper-slide img {
    height: 900px;
  }

}




@media screen and (max-width: 600px) {


  .home-swiper {
    height: 800px;
  }

  .home-swiper-slide img {
    height: 800px;
  }

}


@media screen and (max-width: 500px) {


  .home-swiper {
    height: 700px;
  }

  .home-swiper-slide img {
    height: 700px;
  }
}

@media screen and (max-width: 400px) {


  .home-swiper {
    height: 600px;
  }

  .home-swiper-slide img {
    height: 600px;
  }
}