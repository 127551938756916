.loading-body{
    height: 1080px;
}


.image{
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}