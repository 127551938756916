footer {
  background: #111111;
  height: auto;
  font-family: "Open Sans";
  padding-top: 40px;
  color: #fff;
  /*background-image: url("../../../public/images/0123.png");*/
}

.footer-content {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  z-index: 1;
}

.footer-content h3 {
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 3rem;
}

.footer-content p {
  padding-bottom: 1rem;
  line-height: 28px;
  font-size: 14px;
  margin-top: 1rem;
}

.socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1rem 0;
}

.socials li {
  margin: 0 2rem;
}

.socials a {
  text-decoration: none;
  color: #999999;
}

.footer-icons {
  font-size: 1.6rem;
  transition: color 0.15s ease;
}

.socials a:hover {
  color: #D12027;
}

.footer-logo {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 21rem;
  height: 2.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

hr.solid {
  color: #999999;
}


@media screen and (max-width: 600px) {
  .socials li {
    margin: 0 1rem;
  }
}

@media screen and (max-width: 300px) {
  .socials li {
    margin: 0 0.5rem;
  }
}

@media screen and (max-width: 380px) {
  .footer-logo {
    width: 15rem;
    height: 1.8rem;
  }
}

@media screen and (max-width: 270px) {
  .footer-logo {
    width: 12rem;
    height: 1.4rem;
  }
}

@media screen and (max-width: 200px) {
  .footer-logo {
    width: 8rem;
    height: 3.5rem;
  }
  .socials {
    list-style: none;
    display: block;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0 1rem 0;
  }
}

@media screen and (max-width: 160px) {
  .footer-logo {
    width: 6rem;
    height: 2.5rem;
  }
}
