:root {
  --PrimaryColor: hsl(0, 93%, 27%);
  --SecondryColor: hsl(0, 76%, 47%);
  --gradientColor: linear-gradient(to right,
      hsl(187, 85%, 43%),
      hsl(199, 100%, 33%));

  --redGradientColor: linear-gradient(to right top,
      hsl(0, 93%, 27%),
      hsl(0, 85%, 43%),
      hsl(0, 76%, 47%),
      hsl(0, 64%, 77%));
  --whiteColor: hsl(0, 14%, 1%);
  --blackColor: hsl(201, 33%, 16%);
  --textColor: hsl(240, 4%, 36%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyText: rgb(190, 190, 190);
  --inputColor: rgb(239, 239, 239);
  --bodyColor: rgb(240, 240, 246);
  --cardBG: rgb(225, 225, 235);
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: linear-gradient(to right, #F5DEB3, #FFE4C7, #D2B48C);*/
  background-color: rgba(0, 0, 0, 0.742);
  /*background-image: url("../../../public/images/0123.png");     #E4DBC7            rgba(0, 0, 0, 0.742)   */

  /*
  background: var(--whiteColorDeam);
  */
  width: 100%;
  padding: 0 1rem 0 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(2, 15, 29, 0.242);
  /*background: rgba(1, 1, 1, 0.947) 100%;*/
  top: 0;

  .logo {
    color: var(--blackColor);
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 25px;
      color: var(--PrimaryColor);
      transform: translateY(5px);
    }
  }

  .navImage {
    height: 70px;
  }




  @media screen and (max-width: 769px) {
    .navBar {
      position: absolute;
      background: var(--whiteColorDeam);
      height: max-content;
      width: 80%;
      border-radius: 1rem;
      top: -50rem;
      left: 50%;
      padding: 2rem;
      transform: translate(-50%);
      z-index: 2000;
      box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
      transition: top 0.3s ease-in-out;



      .navLists {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin: auto;
        padding: 0.5rem 0;

        .navItem {
          padding: 0.5rem 0;

          .navLink {
            color: var(--textColor);
            font-size: 0.9rem;
            font-weight: 600;

            &:hover {
              color: var(--PrimaryColor);
            }
          }
        }

        .btn {
          margin-top: 1rem;

          a {
            font-weight: 600;
            color: var(--whiteColor);
          }
        }
      }

      .closeNavbar {
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        color: var(--PrimaryColor);

        &:hover {
          color: var(--SecondryColor);
        }
      }
    }

    .activeNavbar {
      top: 7rem;
    }

    .navImage {
      height: auto;
      width: 130px;
    }
  }

  .toggleNavbar {
    .icon {
      font-size: 30px;
      color: white;
    }

    /*its not working !!!*/
    .toggleNavbar:hover .icon {
      color: var(--SecondryColor);
    }
  }
}

@media screen and (min-width: 770px) {

  .toggleNavbar,
  .closeNavbar {
    display: none;
  }

  .header {
    padding: 0 1.5rem 0 0;
    /*background-size: 30%;*/

    .navBar {
      .navLists {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        .navItem {
          .navLink {
            color: white;
            padding: 0 1rem;
            font-size: 1.1rem;
            font-weight: 600;

            &:hover {
              border-bottom: 3px red solid;
              outline: none;
              cursor: pointer;
              color: red;
              transition: all 0.05s ease-in-out;


            }
          }
        }

        .btn {
          margin-left: 1rem;
        }
      }
    }
  }
}


















/*
              padding: 0.4rem 1rem;
              background-image: linear-gradient(
                to right,
                #b90b0b 0%,
                rgba(249, 48, 48, 0.75) 100%
              );
              box-shadow: 0 4px 15px 0 rgba(249, 48, 48, 0.75);
              border-radius: 0.5rem;
              border: 1px hsl(0, 93%, 27%) solid;
              outline: none;
              cursor: pointer;
              color: white;
*/

/*
              box-shadow: 0 4px 15px 0 #d91920;
              width: 100%;
              border-radius: 0.5rem;
              padding: 0.4rem 1rem;
              background-image: linear-gradient(
                to right,
                #b90b0b 0%,
                rgba(244, 79, 79, 0.75) 100%
              );
              color: white;
              */