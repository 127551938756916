/*.contact-us {
  padding-top: 120px;
  background-image: url("../../../public/images/ch2.png");
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  border: 2px solid gray;
  margin: 0 2rem 4rem 2rem;
  font-family: "Poppins", sans-serif;
}
*/

.contact-us {
  position: relative; /* Ensure it serves as the parent for the overlay */
  background-image: 
    url("../../../public/images/dishleft.webp"),
    url("../../../public/images/bite.webp");      /* Cover image */
  background-repeat: no-repeat, no-repeat;
  background-position: center left, top right;
  background-size: 30%, 20%; /* Auto for the top-right image, cover for the background */
  background-color: #de2727;
  color: white;
  /*
  border: 2px solid gray;
  margin: 0 2rem 4rem 2rem;
  */
  padding: 10rem 2rem 3rem 2rem;
  font-family: "Poppins", sans-serif;
}

.contact-us::before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.3); /* Adjust the color and opacity as needed */
z-index: 1;
}

.contact-us * {
position: relative; /* Ensures content appears above the overlay */
z-index: 2;
}

.contact-us h1 {
  font-size: 48px;
  text-align: center;
  font-weight: 600;
}

.contact-us h2 {
  font-size: 32px;
  padding: 2rem 2rem 0 2rem;
  font-weight: 500;
}
.contact-us h3 {
  padding: 0 2rem;
  text-align: center;
  padding: 4rem 0 3rem 0;
}

.contact-us p {
  padding: 1rem 2rem 3rem 2rem;
  font-size: 20px;
  color: #dedbdb;
}

.contact-us hr.solid {
  color: white;
  margin: 0 4rem 4rem 4rem;
}

.contact-us ul {
  padding: 0.5rem 2rem 0.5rem 4rem;
  display: flex;
  flex-wrap: wrap;
}
.contact-us li {
  display: list-item;
  font-size: 20px;
  color: #dedbdb;
  padding: 0.4rem 0 0.4rem 0;
  display: block;
  width: 100%;
}

@media (max-width: 1000px) {
  .contact-us ul {
    flex-direction: column;
  }
  .contact-us li {
    width: 100%; /* Each item takes full width */
  }
}

@media (max-width: 600px) {
  .contact-us {
    padding: 5rem 0.8rem 4rem 0.8rem;
    background-size: 30%, 30%;
  }
  .contact-us hr.solid {
    margin: 0 2rem 2rem 2rem;
  }
  .contact-us h2 {
    padding: 2rem 0.5rem 0 0.5rem;
  }
  .contact-us ul {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .contact-us li {
    font-size: 16px;
  }
}

@media (max-width: 360px) {

  .contact-us {
    padding: 5rem 0.8rem 4rem 0.8rem;
    background-size: 20%, 30%;
  }
  .contact-us h1 {
    font-size: 28px;
  }
  .contact-us h2 {
    font-size: 20px;
  }

  .contact-us hr.solid {
    margin: 0 1rem 1rem 1rem;
  }
}
