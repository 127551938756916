/*.about-us {
  padding-top: 120px;
  background-image: url("../../../public/images/ch2.png");
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  border: 2px solid gray;
  margin: 0 2rem 4rem 2rem;
  font-family: "Poppins", sans-serif;
}
  */

  .about-us {
    position: relative; /* Ensure it serves as the parent for the overlay */
    background-image: 
      url("../../../public/images/dishleft.webp"),
      url("../../../public/images/bite.webp");      /* Cover image */
    background-repeat: no-repeat, no-repeat;
    background-position: bottom left, top right;
    background-size: 12%, 20%; /* Auto for the top-right image, cover for the background */
    background-color: #de2727;
    color: white;
    padding: 10rem 2rem 10rem 2rem;
    font-family: "Poppins", sans-serif;
  }

.about-us::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Adjust the color and opacity as needed */
  z-index: 1;
}

.about-us * {
  position: relative; /* Ensures content appears above the overlay */
  z-index: 2;
}


.about-us h1 {
  font-size: 48px;
  text-align: center;
  font-weight: 600;
}

.about-us h2 {
  font-size: 32px;
  padding: 5rem 2rem 0 2rem;
  font-weight: 500;
}
.about-us h3 {
  padding: 1rem 2rem 0.8rem 2rem;
}

.about-us p {
  padding: 1rem 2rem 1.5rem 2rem;
  font-size: 20px;
  color: #dedbdb;
}

.about-us hr.solid {
  color: white;
  margin: 0 4rem 4rem 4rem;
}

@media (max-width: 1000px) {
  .about-us ul {
    flex-direction: column;
  }
  .about-us li {
    width: 100%; /* Each item takes full width */
  }
}

@media (max-width: 600px) {
  .about-us {
    padding: 5rem 0.8rem 4rem 0.8rem;
    background-size: 20%, 30%;
  }
  .about-us hr.solid {
    margin: 0 2rem 2rem 2rem;
  }
  .about-us h2 {
    padding: 2rem 0.5rem 0 0.5rem;
  }
  .about-us h3 {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    font-size: 16px;
  }
  .about-us p {
    font-size: 16px;
    padding: 1rem 0.5rem 2rem 0.5rem;
  }
}

@media (max-width: 360px) {
  .about-us h1 {
    font-size: 28px;
  }
  .about-us h2 {
    font-size: 20px;
  }

  .about-us hr.solid {
    margin: 0 1rem 1rem 1rem;
  }
}
